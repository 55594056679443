import Mock from "../mock";

const database = {
  information: {
    name: "Mathieu DRAPALA",
    aboutContent:
      "Développeur passionné dans le domaine du web depuis plus de 4 ans, actuellement développeur web full-stack.",
    age: 25,
    phone: "",
    nationality: "Française",
    language: "Français",
    email: "mathieu@drapala.fr",
    address: "",
    freelanceStatus: "",
    socialLinks: {
      facebook: "",
      twitter: "",
      pinterest: "",
      behance: "",
      linkedin: "https://www.linkedin.com/in/mathieu-drapala-3440b814a/",
      dribbble: "",
      github: "https://github.com/MDrapala",
    },
    brandImage: "/images/brand-image.jpg",
    aboutImage: "/images/paysage.JPG",
    aboutImageLg: "/images/paysage.JPG",
    cvfile: "/files/Drapala_Mathieu.pdf",
  },
  services: [
    {
      title: "Développement Front-end",
      icon: "code",
      details:
        "Ce que j'apprécie dans le monde du front-end est de pouvoir obtenir un visuel très rapidement!",
    },
    {
      title: "Développement Back-end",
      icon: "database",
      details: "",
    },
    // {
    //   title: "Application Mobile",
    //   icon: "mobile",
    //   details:
    //     "J'aime l'évolution des applications de nos jours. La création de mini application.",
    // },
  ],
  reviews: [
    {
      id: 3,
      content:
        "Votre sens des responsabilités est total et nous a permis de partager avec vous un grand nombre de choses dans un parfait climat de confiance.",
      author: {
        name: "Fabrice PERNOL",
        designation:
          "Founder AVLINK • Président Association Little Citizens for Climate",
      },
    },
    {
      id: 2,
      content:
        "Mathieu est un développeur passioné et enthousiaste. Il fait preuve d'une grande rigueur et exécute avec le plus grand soin les missions qui lui sont confiées.",
      author: {
        name: "Pierre",
        designation: "Freelance • Développeur Full-Stack",
      },
    },
    {
      id: 1,
      content:
        "Vous recherchez un collaborateur investi ? Monsieur Drapala est votre homme. Vous recherchez un collaborateur réactif, serviable et dont les qualités programmatives permettront de mener à bien des projets complexes ? Monsieur Drapala est votre homme. Au cours de notre collaboration, il a contribué à faire évoluer notre CRM, à développer des outils de reportings dont la direction se sert encore tous les jours.",
      author: {
        name: "Arney-David Bader",
        designation: "Chef de projet Production pour AVA6",
      },
    },
  ],
  skills: [
    {
      title: "TypeScript",
      value: 0,
    },
    {
      title: "Javascript",
      value: 0,
    },
    {
      title: "ReactJS",
      value: 0,
    },
    {
      title: "NodeJS",
      value: 0,
    },
    {
      title: "Redux",
      value: 0,
    },
    {
      title: "NextJS",
      value: 0,
    },
    {
      title: "SQL",
      value: 0,
    },
    {
      title: "NoSQL",
      value: 0,
    },
    {
      title: "TailwindCSS",
      value: 0,
    },
    {
      title: "",
      value: 0,
    },
    {
      title: "Github",
      value: 0,
    },
    {
      title: "Slack",
      value: 0,
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 0,
        year: "Nov 2023 - Fév 2024",
        position: "Développeur front-end",
        company: "Webcentric - CDI",
        details:
          "Développement de composants pour une librairie interne • Utilisation de GitLab pour la gestion du code source • Développement d'un back-office en ReactJS (Hooks), TypeScript et MobX",
      },
      {
        id: 1,
        year: "Oct 2020 - Sept 2023",
        position: "Développeur full-stack",
        company: "FLIM - Alternance",
        details:
          "Conception du nouveau back-office • Mise en place de fonction dans firebase • Intégration de nouvelles features (Web et Mobile) • CI/CD avec Github Action",
      },
      {
        id: 2,
        year: "Nov 2019 - Dec 2019",
        position: "Développeur Web",
        company: "Little citizens for Climate - Stage",
        details:
          "• Mise en place de G-Suite • Création générateur de signature de mail • Mise en place newsletter + RGPD",
      },
      {
        id: 3,
        year: "Sep 2018 - Fev 2019",
        position: "Analyste-programmeur junior",
        company: "Actuassur Conseil - Alternance",
        details:
          "Développement du back-office (PHP, HTML, CSS (Bootstrap)) • Maintenance du parc informatique • Gestion de base de données (Access)",
      },
      {
        id: 4,
        year: "Juil 2018",
        position: "Technicien fibre optique",
        company: "DMIT SARL - Stage",
        details:
          "Installation et soudure de la fibre • Remplacement, configuration et maintenance informatique • Prise d'appels et résolution par téléphone.",
      },
      {
        id: 5,
        year: "Juil 2016",
        position: "Technicien de maintenance électrique",
        company: "Hôptial Roger Prévôt- Stage",
        details:
          "Participation au câblage d'une pompe à eaux (Châteaux d'eau de l'hôpital).",
      },
    ],
    educationExperience: [
      {
        id: 0,
        year: "2021 - 2023",
        graduation: "MBA Développeur Full-Stack",
        university: "MyDigitalSchool - Paris",
        details: "",
      },
      {
        id: 1,
        year: "2020 - 2021",
        graduation: "Bachelor Développeur Digital",
        university: "MyDigitalSchool - Paris",
        details: "",
      },
      {
        id: 2,
        year: "2017 - 2020",
        graduation:
          "BTS Service informatique au organisations, Solutions logicielles et applications métiers",
        university: "École IRIS - Paris",
        details: "",
      },
      {
        id: 3,
        year: "2014 - 2017",
        graduation:
          "Baccalauréat professionnel en électrotechnique, énergie, équipements communicants",
        university: "Lycée Gustave Effeil - Ermont-Eaubonne",
        details: "",
      },
    ],
  },
  portfolios: [
    {
      id: -2,
      title: "NFT Marketplace",
      subtitle: "Intégration d'une maquette",
      imageUrl: "/images/projects/nft-marketplaces/Wordpress.png",
      // largeImageUrl: [
      //   "/images/projects/nft-marketplaces/home.png",
      //   "/images/projects/nft-marketplaces/hub.png",
      //   "/images/projects/nft-marketplaces/game.png",
      // ],
      url: "https://wpdrapala.alwaysdata.net/",
    },
    {
      id: -1,
      title: "GithubSync",
      subtitle: "Synchronisation de dépôt Github",
      imageUrl: "/images/projects/githubSync/GithubSync.png",
      largeImageUrl: ["/images/projects/githubSync/main.gif"],
    },
    {
      id: 0,
      title: "Meltrip",
      subtitle: "En cours de développement",
      imageUrl: "/images/projects/meltrip/logoMeltrip.png",
      largeImageUrl: [
        "/images/projects/meltrip/home.png",
        "/images/projects/meltrip/now.png",
        "/images/projects/meltrip/FAQ.png",
      ],
    },
    {
      id: 1,
      title: "Casety",
      subtitle: "La sécurité, ça ne se braque pas !",
      imageUrl: "/images/projects/casety/logoCasety.png",
      largeImageUrl: [
        "/images/projects/casety/welcome.png",
        "/images/projects/casety/map.png",
      ],
    },
    {
      id: 2,
      title: "Castellane Auto",
      subtitle: "Création d'un CRM pour auto-école.",
      imageUrl: "/images/projects/castellane/logoCastellane.png",
      largeImageUrl: [
        "/images/projects/castellane/page1.png",
        "/images/projects/castellane/page2.png",
        "/images/projects/castellane/page3.png",
        "/images/projects/castellane/page4.png",
        "/images/projects/castellane/page5.png",
        "/images/projects/castellane/page6.png",
        "/images/projects/castellane/page7.png",
        "/images/projects/castellane/page8.png",
        "/images/projects/castellane/page9.png",
        "/images/projects/castellane/page10.png",
        "/images/projects/castellane/page11.png",
        "/images/projects/castellane/page12.png",
        "/images/projects/castellane/page13.png",
        "/images/projects/castellane/page14.png",
        "/images/projects/castellane/page15.png",
      ],
    },
    {
      id: 3,
      title: "FriendsGames",
      subtitle: 'Le jeux "Je n\'ai jamais".',
      imageUrl: "/images/projects/friends-games/FriendsGames.png",
      largeImageUrl: [
        "/images/projects/friends-games/menu.png",
        "/images/projects/friends-games/hub.png",
        "/images/projects/friends-games/game.png",
      ],
      // url: "https://friends-games.herokuapp.com/",
    },
  ],
  // blogs: [
  //   {
  //     id: 1,
  //     title: "Markdown & Html supported blog.",
  //     featuredImage: "/images/blog-image-1.jpg",
  //     filesource: "../../blog/markdown-html-supported-blog.md",
  //     createDay: "20",
  //     createMonth: "February",
  //     createYear: "2020",
  //   },
  // ],
  contactInfo: {
    phoneNumbers: ["06 28 73 61 95"],
    emailAddress: ["mathieu@drapala.fr"],
    address: "ECOUEN 95440",
  },
};

Mock.onGet("/api/information").reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply((config) => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply((config) => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});
